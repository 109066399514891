import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { FacebookIcon, InstagramIcon, YoutubeIcon } from '../components/global/icons/icons';
import LandingHeader from '../components/global/landing-header/landing-header';
import Layout from '../components/global/layout/layout';

export default function ({ data }) {
    return (
        <Layout footerPosts={data.posts} title="Kontakt" description="So erreichst du mich.">
            <LandingHeader
                text="Kontakt"
                backgroundImage="'/images/kontakt.svg'"
            ></LandingHeader>
            <Container className="mb-5">
                <Row>
                    <Col xs="12" md="6" className="icon-container">
                        <h2 className="font-weight-bold text-pink">Du hast Fragen?</h2>
                        <p className="mb-4">
                            Dir fehlt ein Thema auf dem Blog oder Du hast eine tolle Idee? Dann schicke mir gerne eine Nachricht! Ich freue mich über jede Art von Feedback, Motivation und Ideen.
                        </p>
                        <h2 className="font-weight-bold text-pink">So kannst du mich erreichen</h2>
                        <p>
                            Du erreichst mich am besten per E-Mail unter{' '}
                            <a className="unstyled font-weight-bold text-pink" href="mailto:calvin@mindfoe.de">calvin@mindfoe.de</a>{' '}
                            oder auf meinen Social-Media-Kanälen.
                        </p>
                        <FacebookIcon pathClass="fill-grey" linkClass="mr-2" size={36}/>
                        <InstagramIcon pathClass="fill-grey" linkClass="mr-2" size={36}/>
                        <YoutubeIcon pathClass="fill-grey" size={36}/>
                    </Col>
                    <Col xs="12" md="6" className="mt-5 mt-md-0">
                      <Img className="w-100" alt="Calvin Sievers" fluid={data.image_calvin_1_sw.childImageSharp.fluid}/>
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
}

export const pageQuery = graphql`
  {
    image_calvin_1_sw: file(name: {eq: "calvin-1-sw"}) {
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    posts: allPrismicBlogPost(limit: 10, sort: {fields: first_publication_date, order: DESC}) {
      nodes {
        uid
        data {
          title {
            text
          }
          excerpt {
            text
          }
          title_image {
            url
          }
        }
        first_publication_date_from_now: first_publication_date(fromNow: true)
        first_publication_date_time: first_publication_date(formatString: "hh:mm")
        first_publication_date: first_publication_date(formatString: "DD.MM.YYYY")
        first_publication_date_day: first_publication_date(formatString: "dddd", locale: "de-DE")
        tags
      }
    }
  }
`
